import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Report from "./pages/Report";
import Sidebar from "./components/Sidebar";
import { RecoilRoot } from "recoil";
import Checkup from "./pages/Checkup";
import CheckupDetail from "./pages/CheckupDetail";
import CheckupReport from "./pages/CheckupReport";
import CheckupCollectors from "./pages/CheckupCollectors";
import { Reset } from "styled-reset";
import BetterThanFeedbackMain from "./pages/betterThanFeedback/BetterThanFeedbackMain";
import BetterThanFeedbackDetail from "./pages/betterThanFeedback/BetterThanFeedbackDetail";
import Layout from "./layouts/Layout";
import GlobalStyle from "./GlobalStyle";
import ReactPdf from "./components/ReactPdf";
import Wordcloud from "./components/Wordcloud";
import ManagerReport from "./components/betterThanFeedback/ManagerReport";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <GlobalStyle />
    <RecoilRoot>
      <Reset />
      {/* <Sidebar /> */}
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<BetterThanFeedbackMain />} />
          <Route
            path="/betterthanfeedback"
            element={<BetterThanFeedbackMain />}
          />
          <Route
            path="/betterthanfeedback/detail"
            element={<BetterThanFeedbackDetail />}
          />
          {/* <Route path="/betterthanfeedback/report" element={<Report />} /> */}
        </Route>
        <Route path="/report" element={<ReactPdf />} />
        <Route path="/manager/report" element={<ManagerReport />} />
        <Route path="/wordcloud" element={<Wordcloud />} />
        <Route path="/checkup" element={<Checkup />} />
        <Route path="/checkup/:projectId" element={<CheckupCollectors />} />
        <Route
          path="/checkup/:projectId/:collectorId"
          element={<CheckupDetail />}
        />
        <Route
          path="/checkup/:projectId/:collectorId/report"
          element={<CheckupReport />}
        />
      </Routes>
    </RecoilRoot>
  </BrowserRouter>
  // </React.StrictMode>
);
